import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  fontSizes,
  fontWeights,
  fontFamilies,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    lineHeight: 1.4,
    textDecoration: 'none',
    textTransform: 'unset',
    cursor: 'pointer',
    [breakpoints.up('md')]: {
      padding: '0.75rem 1.5rem',
    },
    '&.primary': {
      width: '100%',
      padding: '1rem 2.25rem',
      borderRadius: borderRadiuses.lg,
      background: palette.custom.main,
      color: palette.common.white,
      fontSize: fontSizes.sm,
      lineHeight: 1.25,
      [breakpoints.up('md')]: {
        width: 'fit-content',
        lineHeight: 1,
      },
      '&:focus-visible': {
        outline: `1px dashed ${palette.primary.dark}`,
        outlineOffset: 2,
      },
    },
    '&.light': {
      width: 'fit-content',
      padding: '6px 1rem',
      borderRadius: borderRadiuses.md,
      background: palette.common.white,
      color: palette.primary.dark,
      fontSize: fontSizes.xs,
      lineHeight: 1.6,
      [breakpoints.up('md')]: {
        padding: '0.75rem 1.25rem',
        borderRadius: borderRadiuses.lg,
        fontSize: fontSizes.sm,
      },
      '&:focus-visible': {
        outline: `1px dashed ${palette.common.white}`,
        outlineOffset: 2,
      },
    },
    '&.text': {
      width: '100%',
      padding: 0,
      borderRadius: 0,
      background: 'transparent',
      color: palette.primary.dark,
      fontSize: fontSizes.sm,
      lineHeight: 1.25,
      [breakpoints.up('md')]: {
        width: 'fit-content',
        lineHeight: 1,
      },
      '&:focus-visible': {
        outline: `1px dashed ${palette.primary.dark}`,
        outlineOffset: 2,
      },
    },
  },
  isUnderline: {
    textDecoration: 'underline',
  },
}));
