import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  spacing,
  breakpoints,
  palette,
  fontSizes,
  siteMediumWidth,
  fontWeights,
  breakpointXl,
}: Theme) => ({
  footer: {
    backgroundColor: palette.info.dark,
    padding: '2rem 1rem',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: siteMediumWidth,
    margin: '0 auto',
    [breakpoints.up(breakpointXl)]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  supportContainer: {
    display: 'grid',
    rowGap: spacing(2),
    marginBottom: '1.5rem',
    [breakpoints.up(breakpointXl)]: {
      marginBottom: '0.25rem',
    },
  },
  supportMotto: {
    maxWidth: 'unset',
    marginTop: 0,
    marginBottom: '1.25rem',
    color: palette.grey.A100,
    fontSize: fontSizes.xs,
  },
  phoneNumber: {
    fontWeight: fontWeights.medium,
  },
  logoGroup: {
    [breakpoints.up(breakpointXl)]: {
      display: 'flex',
    },
  },
  logoWrapper: {
    width: '4rem',
    height: '4rem',
    marginBottom: '1.25rem',
    [breakpoints.up(breakpointXl)]: {
      display: 'flex',
      alignSelf: 'flex-start',
      height: '100%',
      marginBottom: 0,
      marginRight: '1.25rem',
    },
    '& img': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
    },
  },
  policy: {
    marginBottom: '1.5rem',
    [breakpoints.up(breakpointXl)]: {
      marginBottom: 0,
    },
    [breakpoints.between(breakpointXl, 1090)]: {
      maxWidth: '20.625rem',
    },
  },
  legal: {
    width: '100%',
    [breakpoints.up(breakpointXl)]: {
      marginTop: '1rem',
    },
    '& p': {
      margin: 0,
      opacity: 0.3,
      color: palette.common.white,
      fontSize: fontSizes.xxs,
      lineHeight: 1.3,
    },
  },
  link: {
    color: palette.common.white,
    fontSize: fontSizes.xxs,
    lineHeight: 1.3,
  },
}));
