import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  fontWeights,
  siteMediumWidth,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '1rem',
    width: '100%',
    maxWidth: siteMediumWidth,
    padding: '1.5rem 1rem',
    background: palette.grey.A100,
    [breakpoints.up('md')]: {
      width: 'calc(100% - 2rem)',
      gap: 'unset',
      padding: '2rem',
      margin: '0 auto 2.5rem',
      borderRadius: borderRadiuses.md,
    },
    '& .MuiTabs-root': {
      width: '100%',
      maxWidth: '29rem',
      minHeight: 'unset',
      margin: '0 auto 0.25rem',
      padding: 2,
      borderRadius: '2rem',
      background: palette.common.white,
      [breakpoints.up('md')]: {
        marginBottom: '2rem',
      },
    },
    '& .MuiTab-fullWidth': {
      minHeight: 'unset',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      borderRadius: '2.25rem',
      color: palette.text.primary,
      fontSize: fontSizes.sm,
      fontWeight: fontWeights.regular,
      lineHeight: 1,
      textTransform: 'none',
      opacity: 1,
      '&.Mui-selected': {
        background: palette.primary.main,
        color: palette.common.white,
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    width: '100%',
    padding: '2rem 1rem',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: '0.375rem',
    background: palette.common.white,
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexBasis: '50%',
      width: 'auto',
      height: '100%',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: 1.2,
    textAlign: 'center',
    color: palette.text.primary,
    marginTop: 0,
    marginBottom: 0,
    [breakpoints.up('md')]: {
      marginBottom: '1.25rem',
      fontSize: fontSizes.xxl,
    },
  },
  image: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '100%',
    borderRadius: borderRadiuses.md,
    lineHeight: 0,
    overflow: 'hidden',
    [breakpoints.up('md')]: {
      flexBasis: '50%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      [breakpoints.up('md')]: {
        width: 'auto',
        height: '100%',
      },
    },
  },
  panelInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridAutoRows: '1fr',
      gap: '1.25rem',
      maxHeight: '20rem',
    },
    [breakpoints.up('lg')]: {
      maxHeight: '15rem',
    },
  },
  qrCode: {
    padding: '0.375rem',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: borderRadiuses.sm,
    lineHeight: 0,
  },
  noDownload: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
