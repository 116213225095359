import React, { FC, useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import { DEFAULT_FILTERS } from '../../../../constants/ecp-locator';
import { useSetFilterParams } from '../../../../graphql/ecp-locator/actions/set-filter-params';
import {
  filterParams,
  setEcpFormSubmitted,
} from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { useMyLocation } from '../../../../hooks/use-my-location';
import { useUpdateEcpStores } from '../../../../hooks/use-update-ecp-stores';
import { IFittingProps } from '../ecp-locator-settings.models';
import { EcpStoreType } from '../../../../rest/ecp-locator';
import { ServiceTypeFilters } from '../../../../graphql/ecp-locator/models/filter-params.models';
import { GET_IS_USER_LOGGED_IN } from '../../../../graphql/user/queries';

export const withDefaultFiltersAndStoresList = <T extends unknown>(Component: FC<T>) => (
  props: T & IFittingProps,
): JSX.Element => {
  const {
    isFitted,
    isLoading,
    isError,
    isFittingsLoaded = true,
  } = props;
  const { isLocationDefined, coords } = useMyLocation();
  const { setFilterParamsToCache } = useSetFilterParams();
  const [initLocationValue, setInitLocationValue] = useState(isLocationDefined);
  const { updateStores, isStoresLoading, isStoresError } = useUpdateEcpStores();
  const currentFilters = useReactiveVar(filterParams);
  const { isFirstTimeLoading } = currentFilters;
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  useEffect(() => {
    const isLocationAllowedClick = isLocationDefined && !initLocationValue;
    const isLocationDeniedByUser = !isLocationDefined && initLocationValue;

    // set default filters and stores
    if (isFittingsLoaded && isFirstTimeLoading) {
      const filters = isLoggedIn ? DEFAULT_FILTERS : {
        ...DEFAULT_FILTERS,
        isFiltersChanged: true,
        services: isLoggedIn ? null : EcpStoreType.fitter as ServiceTypeFilters,
      };

      if (isLocationDefined) {
        updateStores({ filters, userLocation: coords });
        // Set value to notify error watcher if error should be shown.
        setEcpFormSubmitted(true);
      } else {
        setFilterParamsToCache(filters);
      }
    }

    // set stores if user click allow button on location browser popup
    if (isLocationAllowedClick) {
      updateStores({ userLocation: coords });
      setInitLocationValue(true);
      // Set value to notify error watcher if error should be shown.
      setEcpFormSubmitted(true);
    }

    // set default state if user deny location access in browser
    if (isLocationDeniedByUser) {
      setInitLocationValue(false);
      // todo: set map default state
    }
  }, [isFittingsLoaded, isFitted, isLocationDefined]);

  return (
    <Component
      {...props}
      isLoading={isLoading || isStoresLoading}
      isError={isError || isStoresError}
      updateStores={updateStores}
    />
  );
};
