import { useReactiveVar } from '@apollo/client';

import qrCodeImage from '../../../../../../assets/images/qr-code.png';
import { ReactComponent as AppStore } from '../../../../../../assets/images/app-store.svg';
import { ReactComponent as GooglePlay } from '../../../../../../assets/images/google-play.svg';

import { useStyles } from './download-app.styles';
import { ExternalLinks, InternalLinks } from '../../../../../../constants';
import { CampaignIcon } from '../../../../../common/campaign-icon';
import { setIsLocatorIconsCampaign } from '../../../../../../graphql/configuration/configuration.cache';
import { useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

export const DownloadApp = (): JSX.Element => {
  const classes = useStyles();
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();

  return (
    <div className={classes.apps} data-testid='download-app'>
      <div className={classes.stores}>
        <a href={ExternalLinks.mobileMarket} target='_blank' data-testid='appStore-link'>
          <AppStore className={classes.appStore} />
        </a>
        <a href={ExternalLinks.mobileMarket} target='_blank' data-testid='googlePlay-link'>
          <GooglePlay className={classes.googlePlay} />
        </a>
      </div>
      <div className={classes.qrCode} data-testid='qr-code'>
        <a
          href={isMobile ? ExternalLinks.mobileMarket : InternalLinks.registration}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={qrCodeImage}
            alt={intl.formatMessage({ id: 'common.qrCode' })}
            className={classes.qrCode}
          />
        </a>
      </div>
      {isCampaignIcon && <CampaignIcon />}
    </div>
  );
};
