import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, borderRadiuses, palette, breakpointXl }: Theme) => ({
  apps: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '1.5rem',
    [breakpoints.up(breakpointXl)]: {
      flexDirection: 'row-reverse',
      width: 'fit-content',
      padding: 0,
      marginBottom: 0,
    },
  },
  stores: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& a': {
      lineHeight: 0,
    },
  },
  appStore: {
    width: 129,
    height: 37,
  },
  googlePlay: {
    width: 129,
    height: 37,
  },
  qrCode: {
    padding: '0.375rem',
    width: '5.875rem',
    height: '5.875rem',
    borderRadius: borderRadiuses.sm,
    background: palette.common.white,
    marginLeft: '3rem',
    [breakpoints.up(breakpointXl)]: {
      marginLeft: 0,
      marginRight: '1rem',
    },
    '& img': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
    },
  },
}));
