import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useGoBack } from '../../../../../hooks/use-go-back';
import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './header.styles';
import { Logo } from '../../../../common/logo';
import { Button } from '../../../../common/button';
import { Button as ButtonNew } from '../../../../common/button-new';
import { Icon, IconTypes } from '../../../../common/icon';
import { UserMenu } from './user-menu';
import { IHeaderProps } from './header.models';
import { BarcodeAction } from '../../barcode-action';
import { InternalLinks } from '../../../../../constants';

export const Header = ({ isLoggedIn }: IHeaderProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { hasPreviousLocationState } = useGoBack();

  return (
    <header
      className={classes.root}
      data-testid="header"
    >
      <ContentWrapper>
        <div className={classes.container}>
          <div className={classes.primaryNavContainer}>
            <Link to="/" className={classes.logoWrapper}>
              <Logo size="md" />
            </Link>
            {isLoggedIn && (
              <Button
                color="info"
                startIcon={<Icon type={IconTypes.help} />}
                data-testid="header-help-link"
                hideText={!isTablet}
                labelClassName="normal"
                underline
                to={{
                  hash: '#help',
                }}
              >
                <FormattedMessage id="header.help" />
              </Button>
            )}
            {(isTablet || !isLoggedIn) && (
              <Button
                color="info"
                to={InternalLinks.programPage}
                state={hasPreviousLocationState}
                data-testid="header-about-program-link"
                startIcon={<Icon type={IconTypes.aboutProgram} />}
                hideText={!isTablet}
                labelClassName="normal"
                underline
              >
                <FormattedMessage id="header.about" />
              </Button>
            )}
          </div>
          <div className={classes.secondaryNavContainer}>
            {isLoggedIn
              ? (
                <>
                  <BarcodeAction isLabelHidden={!isTablet} />
                  {isDesktop && (
                    <Button
                      color="info"
                      data-testid="header-ecp-link"
                      to={InternalLinks.ecpLocator}
                      state={hasPreviousLocationState}
                      startIcon={<Icon type={IconTypes.map} />}
                      labelClassName="normal"
                      underline
                    >
                      {intl.formatMessage(
                        { id: 'header.menuItem.findOptics' },
                        { sup: (chunks: ReactNode) => <sup className={classes.sup}>{chunks}</sup> },
                      )}
                    </Button>
                  )}
                  <UserMenu />
                </>
              ) : (
                <>
                  <ButtonNew
                    style="text"
                    data-testid="header-ecp-link"
                    to={InternalLinks.ecpLocator}
                    state={hasPreviousLocationState}
                    startIcon={<Icon className={classes.mapIcon} type={IconTypes.map} />}
                    hideText={!isTablet}
                    isUnderline
                  >
                    {intl.formatMessage({ id: 'header.menuItem.findOptics.notLoggedIn' })}
                  </ButtonNew>
                  <Button
                    color="info"
                    to={InternalLinks.login}
                    state={hasPreviousLocationState}
                    startIcon={<Icon className={classes.userIcon} type={IconTypes.userBlack} />}
                    data-testid="header-login-link"
                    hideText={!isTablet}
                    labelClassName="normal"
                    underline
                  >
                    <FormattedMessage id="header.login" />
                  </Button>
                </>
              )}
          </div>
        </div>
      </ContentWrapper>
    </header>
  );
};
