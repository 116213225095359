import { ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useStyles } from './no-play-store.styles';
import { IModalProps, Modal } from '../../../../../common';
import step1 from '../../../../../../assets/images/no-play-store/step-1.png';
import step2 from '../../../../../../assets/images/no-play-store/step-2.png';
import step3 from '../../../../../../assets/images/no-play-store/step-3.png';
import step4 from '../../../../../../assets/images/no-play-store/step-4.png';
import step5 from '../../../../../../assets/images/no-play-store/step-5.png';
import { InternalLinks } from '../../../../../../constants';

export const NoPlayStore = ({ footer, ...props }: IModalProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const items = [
    {
      content: 'noPlayStore.step-1',
      imgSrc: step1,
    },
    {
      content: 'noPlayStore.step-2',
      imgSrc: step2,
    },
    {
      content: 'noPlayStore.step-3',
      imgSrc: step3,
    },
    {
      content: 'noPlayStore.step-4',
      imgSrc: step4,
    },
    {
      content: 'noPlayStore.step-5',
      imgSrc: step5,
    },
  ];

  return (
    <Modal
      {...props}
      className={classes.root}
      content={(
        <>
          <h2 className={classes.title}>
            <FormattedMessage id="downloadApp.no-play-store" />
          </h2>
          <p className={classes.text}><FormattedMessage id="noPlayStore.description-1" /></p>
          <p className={classes.text}><FormattedMessage id="noPlayStore.description-2" /></p>
          <ul className={classes.cardsList}>
            {items.map((item, itemIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <li className={classes.cardsItem} key={itemIndex}>
                <div className={classes.imgContainer}>
                  <img src={item.imgSrc} alt="" />
                  <span className={classes.index}>{itemIndex + 1}</span>
                </div>
                <span className={classes.cardText}>
                  {intl.formatMessage(
                    { id: item.content },
                    {
                      sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                      a: (chunks: ReactNode) => <a className={classes.link} href={InternalLinks.home}>{chunks}</a>,
                    },
                  )}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
      propsContentStyles={classes.content}
      propsPaperStyles={classes.root}
    />
  );
};
