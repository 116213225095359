import { ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './sign-up-slide.styles';
import { CmsBanner, CmsBannerType } from '../../../../common/cms-banner';
import { Icon, IconTypes } from '../../../../common';
import { useGetBanners } from '../../../../../hooks/use-get-banners';

export const SignUpSlide = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { isBanner, url, loading } = useGetBanners(CmsBannerType.registrationGuide);

  const renderSlide = useCallback(() => {
    if (loading) {
      return null;
    }

    if (isBanner) {
      return (
        <CmsBanner
          url={url}
        />
      );
    }
  }, [isBanner]);

  return (
    <div className={classes.root}>
      <ContentWrapper withoutPaddings>
        <div className={classes.slide} data-testid="slide">
          <div aria-hidden="true" data-testid="slide-img">
            {renderSlide()}
          </div>
          <div className={classes.content} data-testid="slide-content">
            <h1 className={classes.title} data-testid="title">
              {intl.formatMessage(
                { id: 'regGuide.banner.title.old' },
                {
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                  color: (chunks: ReactNode) => <span className={classes.titleEmphasis}>{chunks}</span>,
                },
              )}
            </h1>
            <a
              data-testid="signup-link"
              className={classes.button}
              href={intl.formatMessage({ id: 'regGuide.step1.action.href' })}
            >
              <FormattedMessage
                id="regGuide.banner.button"
              />
            </a>
            <div data-testid="program-notice">
              <p
                className={classes.programNote}
                data-testid="program-notice-paragraph"
              >
                <FormattedMessage id="registration.banner.legal.age" />
                &nbsp;
                <FormattedMessage id="registration.banner.legal.period" />
              </p>
              <p className={classes.programNote} data-testid="program-notice-paragraph">
                <FormattedMessage id="registration.banner.legal.program" />
              </p>
            </div>
            <span
              aria-label={intl.formatMessage({ id: 'registration.banner.disclaimer' })}
              data-testid="disclaimer-wrapper"
            >
              <Icon
                type={IconTypes.slideLegal}
                className={classes.disclaimer}
                aria-hidden={true}
              />
            </span>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
