import { useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { ButtonProps } from './button.models';
import { useStyles } from './button.styles';

export const Button = ({
  children,
  to,
  style,
  ariaLabel,
  onClick,
  hideText,
  startIcon,
  isUnderline,
  ...rest
}: ButtonProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      {to ? (
        <a
          {...rest}
          href={to}
          className={cx(classes.root, style,
            { [classes.isUnderline]: isUnderline })}
          aria-label={ariaLabel && intl.formatMessage({ id: ariaLabel })}
        >
          {startIcon}
          {!hideText && children}
        </a>
      ) : (
        <button
          {...rest}
          aria-label={ariaLabel && intl.formatMessage({ id: ariaLabel })}
          className={cx(classes.root, style,
            { [classes.isUnderline]: isUnderline })}
          onClick={onClick}
        >
          {startIcon}
          {!hideText && children}
        </button>
      )}
    </>
  );
};
