import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import checkedGreen from '../../../../../../assets/images/icons/checked/checked-green.svg';

export const useStyles = makeStyles(({
  breakpoints,
  fontSizes,
}: Theme) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '&.checked li': {
      position: 'relative',
      minHeight: '1.25rem',
      paddingLeft: '2.25rem',
      background: `no-repeat url(${checkedGreen}) left top`,
      backgroundSize: '1.25rem',
      '&:not(:last-of-type)': {
        marginBottom: '0.75rem',
      },
    },
  },
  item: {
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
    },
  },
}));
