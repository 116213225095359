import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { Icon, IconTypes } from '../../../../common';
import { useStyles } from './banner.styles';
import { IBannerProps } from './banner.models';
import {
  ReactComponent as LogoSecondaryPlain,
} from '../../../../../assets/images/logo/logo-secondary-plain.svg';
import { ExternalLinks } from '../../../../../constants';

export const Banner = ({ isLeft, isRight }: IBannerProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={cx(classes.root,
        { [classes.leftBanner]: isLeft },
        { [classes.rightBanner]: isRight },
      )}
      data-testid={isRight ? 'right-banner' : 'left-banner'}
      aria-hidden={isLeft}
    >
      <div
        className={classes.logoWrapper}
        data-testid="logo-wrapper"
      >
        <LogoSecondaryPlain
          aria-hidden="true"
          className={classes.logo}
          data-testid="logo-secondary-plain"
        />
      </div>
      <p
        data-testid="marketing-disclaimer"
        className={classes.marketingDisclaimer}
      >
        <span
          data-testid="banner-points"
          className={classes.bannerPoints}
        >
          {intl.formatMessage(
            { id: 'registration.banner.marketing.disclaimer.first' },
            { span: (
              chunks: ReactNode,
            ) => (
              <span
                className={cx(classes.block, classes.value)}
                data-testid="points-value"
              >
                {chunks}
              </span>
            ),
            },
          )}
        </span>
        <span
          data-testid="banner-points-from"
          className={classes.bannerPointsFrom}
        >
          {intl.formatMessage(
            { id: 'registration.banner.marketing.disclaimer.second' },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </span>
      </p>
      <div
        className={classes.legalDisclaimer}
        data-testid="legal-disclaimer"
      >
        {intl.formatMessage(
          { id: 'registration.banner.terms' },
          { a: (chunks: ReactNode) => (
            <a
              href={ExternalLinks.myAcuvueProgramPolicyLink}
              target="_blank"
              className={classes.link}
              data-testid="program-link"
            >
              {chunks}
            </a>
          ),
          p: (chunks: ReactNode) => (
            <p
              className={classes.partial}
              data-testid="legal-disclaimer-partial"
            >
              {chunks}
            </p>
          ),
          },
        )}
        <span
          aria-label={intl.formatMessage({ id: 'registration.banner.disclaimer' })}
          data-testid="disclaimer-wrapper"
        >
          <Icon
            type={IconTypes.regBannerLegal}
            className={classes.disclaimer}
            aria-hidden={true}
          />
        </span>
      </div>
    </div>
  );
};
