export interface IIconProps {
  type: IconTypes,
  size?: IconSizes,
  className?: string,
}

export enum IconTypes {
  email = 'email',
  visibleSecret = 'visibleSecret',
  hiddenSecret = 'hiddenSecret',
  error = 'error',
  warning = 'warning',
  success = 'success',
  radioChecked = 'radioChecked',
  radioUnchecked = 'radioUnchecked',
  checkboxUnchecked = 'checkboxUnchecked',
  checkboxChecked = 'checkboxChecked',
  arrowLeft = 'arrowLeft',
  arrowRight = 'arrowRight',
  arrowRightLight = 'arrowRightLight',
  close = 'close',
  exit = 'exit',
  user = 'user',
  wallet = 'wallet',
  login = 'login',
  lenses = 'lenses',
  map = 'map',
  rate = 'rate',
  rateChosen = 'rateChosen',
  menu = 'menu',
  mobileVertical = 'mobileVertical',
  percent = 'percent',
  help = 'help',
  helpMark = 'helpMark',
  aboutProgram = 'aboutProgram',
  barCode = 'barCode',
  closeMenu = 'closeMenu',
  arrowDown = 'arrowDown',
  externalLink = 'externalLink',
  firstAidKit = 'firstAidKit',
  internetShops = 'internetShops',
  internetShopsWrapped = 'internetShopsWrapped',
  clockBlack = 'clockBlack',
  mapMarkerBlack = 'mapMarkerBlack',
  phoneBlack = 'phoneBlack',
  info = 'info',
  filter = 'filter',
  list = 'list',
  mapView = 'mapView',
  play = 'play',
  wrenchBlueBg = 'wrenchBlueBg',
  reminder = 'reminder',
  datePicker = 'datePicker',
  timeOutside = 'timeOutside',
  gadgets = 'gadgets',
  giftBox = 'giftBox',
  visionCheckTable = 'visionCheckTable',
  bellRoundedBlue = 'bellRoundedBlue',
  lensesRoundedBlue = 'lensesRoundedBlue',
  giftRoundedBlue = 'giftRoundedBlue',
  giftRoundedViolet = 'giftRoundedViolet',
  calendarRoundedBlue = 'calendarRoundedBlue',
  phoneRoundedBlue = 'phoneRoundedBlue',
  glassesRoundedBlue = 'glassesRoundedBlue',
  treeRoundedBlue = 'treeRoundedBlue',
  eyeWithEyelashes = 'eyeWithEyelashes',
  percentRoundedDotted = 'percentRoundedDotted',
  percentRoundedBlue = 'percentRoundedBlue',
  aRoundedBlue = 'aRoundedBlue',
  giftBonus = 'giftBonus',
  noPromo = 'noPromo',
  consumerBan = 'consumerBan',
  puschasesConsumerBan = 'puschasesConsumerBan',
  voucher = 'voucher',
  slideLegal = 'slideLegal',
  regBannerLegal = 'regBannerLegal',
  attention = 'attention',
  creditCard = 'creditCard',
  checked = 'checked',
  exclamationRoundedRed = 'exclamationRoundedRed',
  clockRoundedGreen = 'clockRoundedGreen',
  screenRoundedGreen = 'screenRoundedGreen',
  paintRoundedGreen = 'paintRoundedGreen',
  aoh1d = 'aoh1d',
  aoh1dMobile = 'aoh1dMobile',
  discount = 'discount',
  store = 'store',
  phoneRetro = 'phoneRetro',
  callbackRoundedBlue = 'callbackRoundedBlue',
  emailRoundedBlue = 'emailRoundedBlue',
  benefitOne = 'benefitOne',
  benefitTwo = 'benefitTwo',
  benefitThree = 'benefitThree',
  benefitFour = 'benefitFour',
  deleteModal = 'deleteModal',
  userBlack = 'userBlack',
}

export enum IconSizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  xsm = 'xsm',
  '3xl' = '3xl',
}
