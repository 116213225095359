import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  fontWeights,
  siteMediumWidth,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: siteMediumWidth,
    marginBottom: '1.5rem',
    padding: '1.5rem 0 0',
    background: palette.grey.A100,
    [breakpoints.up('md')]: {
      width: 'calc(100% - 2rem)',
      padding: '0 0 0 2rem',
      margin: '0 auto 2.5rem',
      flexDirection: 'row',
      borderRadius: borderRadiuses.md,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [breakpoints.up('md')]: {
      flexBasis: '57%',
      width: '57%',
      padding: '2rem 0',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.medium,
    lineHeight: 1.2,
    textAlign: 'center',
    color: palette.text.primary,
    marginTop: 0,
    marginBottom: 16,
    [breakpoints.up('md')]: {
      marginBottom: '1.25rem',
      fontSize: fontSizes.xxl,
      textAlign: 'left',
    },
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  list: {
    marginBottom: '2rem',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // boxShadow: '0 3.41px 44.24px 0 #13189b2e',
    [breakpoints.up('md')]: {
      width: '43%',
      flexBasis: '43%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
  },
}));
