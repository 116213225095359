import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ellipse from '../../../../../assets/images/sign-up-banner/ellipse.svg';
import ellipseMobile from '../../../../../assets/images/sign-up-banner/ellipse-mobile.svg';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  fontWeights,
  siteMaxWidth,
}: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: 'calc(100% - 2rem)',
    margin: '1rem auto',
    padding: 16,
    paddingRight: 0,
    overflow: 'hidden',
    background: `linear-gradient(to top right, #38adeb, ${palette.primary.dark} 34%, ${palette.primary.dark} 67%, #39aeec 100%)`,
    [breakpoints.up('sm')]: {
      maxWidth: siteMaxWidth,
    },
    [breakpoints.up('md')]: {
      margin: '1rem auto 4.5rem',
      padding: '0 3% 0 5%',
    },
    [breakpoints.up(1181)]: {
      marginTop: 0,
      padding: '0 6% 0 9%',
    },
  },
  ellipse: {
    position: 'absolute',
    right: 0,
    top: 2,
    width: '24%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ellipseMobile})`,
    backgroundPosition: 'left top',
    backgroundSize: 'cover',
    [breakpoints.up('md')]: {
      backgroundImage: `url(${ellipse})`,
      width: '50%',
    },
    [breakpoints.down(321)]: {
      width: '19%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
    width: '70%',
    height: '100%',
    [breakpoints.up('md')]: {
      flexGrow: 'unset',
      paddingTop: 30,
      paddingBottom: 30,
    },
    [breakpoints.down(321)]: {
      width: '84%',
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.md,
    fontWeight: fontWeights.medium,
    lineHeight: 1.3,
    textAlign: 'left',
    color: palette.common.white,
    marginTop: 0,
    marginBottom: 16,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxxl,
    },
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  description: {
    marginTop: 0,
    marginBottom: 24,
    color: palette.common.white,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.regular,
    lineHeight: 1.3,
    textAlign: 'left',
    [breakpoints.up('md')]: {
      marginBottom: 48,
      fontSize: fontSizes.xl,
    },
  },
  newLine: {
    display: 'block',
  },
  image: {
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    width: '30%',
    [breakpoints.up('md')]: {
      width: 'unset',
    },
    [breakpoints.down(321)]: {
      width: '16%',
    },
    '& img': {
      position: 'absolute',
      width: 130,
      height: 'auto',
      objectFit: 'contain',
      top: '0.25rem',
      margin: 0,
      [breakpoints.up('md')]: {
        position: 'static',
        width: 342,
      },
      [breakpoints.down(321)]: {
        left: '-1.5rem',
      },
    },
  },
}));
