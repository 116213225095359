import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  coloredSection: {
    padding: '2.5rem 0',
    backgroundColor: palette.grey.A100,
    [breakpoints.up('sm')]: {
      padding: '2rem 0 4.5rem',
    },
  },
}));
