import { EcpLocator } from '../ecp-locator-new';
import { Wiki } from '../wiki';
import { SignUpSlide } from '../sign-up-slide';
import { WhyMyAcuvue } from '../why-my-acuvue';
import { PrimaryBannerUnregistered } from '../primary-banner-unregistered';
import { DownloadApp } from '../download-app';
import { useStyles } from './landing-page-not-logged-in.styles';

export const LandingPageNotLoggedIn = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <SignUpSlide />
      <WhyMyAcuvue />
      <DownloadApp />
      <EcpLocator />
      <div className={classes.coloredSection}>
        <Wiki />
        <PrimaryBannerUnregistered />
      </div>
    </>
  );
};
