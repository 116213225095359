import React from 'react';

import { ILensesCardProps } from './lenses-card.models';
import { LensesCard as LensesCardView } from './lenses-card';

export const LensesCard = ({ cardData }: ILensesCardProps): JSX.Element => (
  <LensesCardView
    cardData={cardData}
  />
);
