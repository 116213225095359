import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  spacing,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {},
  logoWrapper: {
    display: 'flex',
    marginBottom: spacing(2),
    [breakpoints.up('md')]: {
      marginBottom: spacing(3),
    },
  },
  link: {
    textDecoration: 'none',
    color: palette.grey.A100,
    fontWeight: fontWeights.regular,
    fontFamily: `${fontFamilies.primary}`,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    '&:hover': {
      textDecoration: 'underline',
    },
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
    },
  },
  policyList: {
    paddingLeft: 0,
    margin: 0,
    listStyleType: 'none',
    '& > *:not(:last-child)': {
      marginBottom: spacing(0.5),
    },
    '& > li': {
      lineHeight: lineHeights.sm,
    },
  },
}));
