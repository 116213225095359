import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  spacing,
  breakpoints,
  palette,
  fontSizes,
  lineHeights,
  borderRadiuses,
  fontWeights,
}: Theme) => ({
  section: {
    height: '100%',
    textAlign: 'center',
    border: '1px solid rgba(235, 250, 255, 0.5)',
    borderRadius: borderRadiuses.sm,
    boxShadow: '0px 4px 10px rgba(1, 62, 126, 0.3)',
    padding: `${spacing(3)} ${spacing(1.25)} ${spacing(3)}`,
    [breakpoints.up('md')]: {
      paddingTop: spacing(4),
    },
  },
  sectionShort: {
    textAlign: 'center',
  },
  text: {
    fontWeight: fontWeights.regular,
    fontFamily: fontFamilies.primary,
    color: palette.common.white,
  },
  title: {
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    margin: `0 auto ${spacing(2.5)}`,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xl,
      lineHeight: lineHeights.xl,
      marginBottom: spacing(2),
    },
  },
  description: {
    maxWidth: 150,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    margin: `0 auto ${spacing(3)}`,
    [breakpoints.up('md')]: {
      maxWidth: 'unset',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.lg,
      marginBottom: 28,
    },
    [breakpoints.up('lg')]: {
      maxWidth: '480px',
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'fit-content',
    margin: '0',
    padding: '8px 16px',
    borderRadius: borderRadiuses.xl,
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontSize: fontSizes.sm,
    lineHeight: 1,
    textDecoration: 'none',
    [breakpoints.up('md')]: {
      padding: '12px 24px',
    },
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  contactWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
}));
