import { useIntl } from 'react-intl';

import qrCode from '../../../../../../assets/images/qr-code-eye.svg';
import { ReactComponent as AppStore } from '../../../../../../assets/images/app-store.svg';
import { ReactComponent as GooglePlay } from '../../../../../../assets/images/google-play.svg';
import { useStyles } from './download-app.styles';
import { ExternalLinks } from '../../../../../../constants';

export const DownloadApp = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.apps} data-testid='download-app'>
      <div className={classes.stores}>
        <a href={ExternalLinks.mobileMarket} target='_blank' data-testid='appStore-link'>
          <AppStore className={classes.appStore} />
        </a>
        <a href={ExternalLinks.mobileMarket} target='_blank' data-testid='googlePlay-link'>
          <GooglePlay className={classes.googlePlay} />
        </a>
      </div>
      <div className={classes.qrCode} data-testid='qr-code'>
        <img
          src={qrCode}
          alt={intl.formatMessage({ id: 'common.qrCode' })}
        />
      </div>
    </div>
  );
};
