import React, { useCallback, MouseEvent as MouseEventReact, Key } from 'react';
import { cx } from '@emotion/css';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
} from '@mui/material';

import { IToggleButtonGroup } from './toggle-button-group.models';
import { useStyles } from './toggle-button-group.styles';
import { TFormExtendedCommonProps } from '../form';

export const ToggleButtonGroup = ({
  options,
  onChange,
  fullWidth,
  withoutMargins,
  standalone,
  variant = 'default',
  exclusive,
  exclusiveExactly,
  error,
  helperText,
  buttonStyles,
  groupStyles,
  ...props
}: TFormExtendedCommonProps<IToggleButtonGroup>): JSX.Element => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: MouseEventReact<HTMLElement>, newValue: string | null) => {
      if (exclusiveExactly) {
        if (newValue) {
          onChange(newValue);
        }
      } else {
        onChange(newValue);
      }
    }, [exclusiveExactly, onChange],
  );

  return (
    <MuiFormControl
      error={error}
      fullWidth={fullWidth}
      classes={{
        root: cx(classes.toggleGroupRoot,
          { [classes.toggleGroupRootWithoutMargins]: withoutMargins },
        ),
      }}
    >
      <MuiToggleButtonGroup
        {...props}
        exclusive={exclusive || exclusiveExactly}
        classes={{
          root: cx(groupStyles,
            { [classes.toggleGroupRootStandalone]: standalone },
          ),
          groupedVertical: cx(classes.groupedVertical, {
            [classes.groupedVerticalStandalone]: standalone,
          }),
        }}
        onChange={handleChange}
      >
        {options.map(
          ({ children, value }) => (
            <MuiToggleButton
              key={value as Key}
              value={value}
              classes={{
                root: cx(classes.toggleButtonRoot, buttonStyles,
                  { [classes.toggleButtonRootFullWidth]: fullWidth },
                  { [classes.toggleButtonRootStandalone]: standalone },
                  { [classes.toggleButtonRootOutlined]: variant === 'outlined' },
                  { [classes.toggleButtonRootOutlinedLight]: variant === 'outlined-light' },
                  // to fix divider color issue
                  { [classes.outlinedStandaloneDivider]: standalone && variant === 'outlined'
                    || variant === 'outlined-light' },
                ),
                selected: classes.toggleButtonSelected,
                sizeSmall: classes.sizeSmall,
              }}
            >
              {children}
            </MuiToggleButton>
          ),
        )}
      </MuiToggleButtonGroup>
      {helperText && (
        <MuiFormHelperText
          classes={{
            root: classes.formHelperTextRoot,
            error: classes.formHelperTextError,
          }}
        >
          {helperText}
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
};
