import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './sign-up-slide.styles';
import { Button } from '../../../../common/button-new';
import { InternalLinks } from '../../../../../constants';
import signUpBanner from '../../../../../assets/images/sign-up-banner/sign-up-banner.png';

export const SignUpSlide = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="slide">
      <span className={classes.ellipse} />
      <div className={classes.content} data-testid="slide-content">
        <h1 className={classes.title} data-testid="title">
          {intl.formatMessage(
            { id: 'signUpBanner.title' },
            {
              span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
            },
          )}
        </h1>
        <p className={classes.description} data-testid="description">
          <FormattedMessage id="signUpBanner.description" />
        </p>
        <Button
          data-testid="signup-button"
          to={InternalLinks.registration}
          style="light"
        >
          <FormattedMessage
            id="signUpBanner.button"
          />
        </Button>
      </div>
      <div className={classes.image} aria-hidden="true" data-testid="slide-img">
        <img src={signUpBanner} alt="" />
      </div>
    </div>
  );
};
