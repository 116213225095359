import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontWeights, fontSizes, lineHeights, palette, borderRadiuses, siteMediumWidth,
}: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: siteMediumWidth,
    margin: '0 auto',
    padding: '2.5rem 1rem',
    [breakpoints.up('md')]: {
      width: 'calc(100% - 2rem )',
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
    borderRadius: borderRadiuses.md,
    border: '1px solid transparent',
    backgroundImage: 'linear-gradient(white, white), linear-gradient(to right, #04b6f8, #e75fd0)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: '15px 10px 22px 0 #0000000D',
    textDecoration: 'none',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '2.25rem',
      marginBottom: '5rem',
    },
    '&:focus-visible': {
      outline: `1px dashed ${palette.primary.dark}`,
      outlineOffset: 2,
    },
  },
  sectionTitle: {
    margin: 0,
    padding: '0 5px',
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: 1.2,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      margin: '0 0 1.5rem',
      padding: 0,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      textAlign: 'left',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    [breakpoints.up('md')]: {
      width: '55.5%',
    },
  },
  imgContainer: {
    width: '100%',
    [breakpoints.up('md')]: {
      width: '34.5%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      objectFit: 'contain',
      borderRadius: borderRadiuses.sm,
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '3.25rem',
    padding: '1rem 2.25rem',
    borderRadius: borderRadiuses.lg,
    background: palette.custom.main,
    color: palette.common.white,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: 1.25,
    [breakpoints.up('md')]: {
      width: 'fit-content',
      height: '2.875rem',
      lineHeight: 1,
    },
  },
  newLine: {
    display: 'block',
  },
}));
