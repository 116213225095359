import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './why-my-acuvue.styles';
import { List } from './list';
import { Button } from '../../../../common/button-new';
import appScreens from '../../../../../assets/images/app-screens.png';
import appScreensMobile from '../../../../../assets/images/app-screens-mobile.png';
import { InternalLinks } from '../../../../../constants';

export const WhyMyAcuvue = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const benefits = [
    'whyMyAcuvue.benefit-1',
    'whyMyAcuvue.benefit-2',
    'whyMyAcuvue.benefit-3',
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h2 className={classes.title} data-testid="why-title">
          {intl.formatMessage(
            { id: 'whyMyAcuvue.title' },
            { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
          )}
        </h2>
        <div className={classes.list} data-testid="why-list">
          <List items={benefits} style="checked" />
        </div>
        <Button
          style="primary"
          to={InternalLinks.registration}
        >
          <FormattedMessage id="common.button.register" />
        </Button>
      </div>
      <div className={classes.image} aria-hidden="true">
        <img src={isMobile ? appScreensMobile : appScreens} alt=""/>
      </div>
    </div>
  );
};
