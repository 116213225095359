import { Appointment } from '../appointment';
import { PrimaryBanner } from '../primary-banner';
import { MyBonusesLensesSection } from '../my-bonuses-lenses-section';
import { Promotions } from '../promotions';
import { VisionProfile } from '../vision-profile';
import { EcpLocator } from '../ecp-locator';
import { Wiki } from '../wiki';
import { useStyles } from './landing-page-logged-in.styles';

export const LandingPageLoggedIn = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Appointment />
      <PrimaryBanner />
        <div className={classes.coloredSection}>
          <MyBonusesLensesSection />
          <Promotions />
          <VisionProfile />
        </div>
      <EcpLocator />
      <Wiki />
    </>
  );
};
