import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  apps: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [breakpoints.up('sm')]: {
      width: '235px',
    },
    [breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  stores: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  appStore: {
    width: 129,
    height: 37,
  },
  googlePlay: {
    width: 129,
    height: 37,
  },
  qrCode: {
    width: 93,
    height: 92,
    cursor: 'pointer',
  },
}));
