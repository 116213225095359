import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ siteMediumWidth }: Theme) => ({
  root: {
    width: 'calc(100% - 2rem)',
    margin: '0 auto',
    maxWidth: siteMediumWidth,
  },
}));
