import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useStyles } from './ecp-locator.styles';
import { InternalLinks } from '../../../../../constants';
import mapMobile from '../../../../../assets/images/ecp-preview/preview-map-mobile.png';
import map from '../../../../../assets/images/ecp-preview/preview-map.png';

export const EcpLocator = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <section
      data-testid="ecp-locator-landing-wrapper"
      aria-label={intl.formatMessage({ id: 'ecp.landing.section.ariaLabel' })}
      className={classes.root}
    >
      <a
        data-testid="inner-container"
        className={classes.container}
        href={InternalLinks.ecpLocator}
      >
        <div className={classes.content}>
          <h2
            data-testid="ecp-locator-landing-title"
            className={classes.sectionTitle}
          >
            {intl.formatMessage(
              { id: isTablet ? 'ecp.preview.title' : 'ecp.preview.title.mobile' },
              {
                sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                span: (chunks: ReactNode) => <span className={classes.newLine}>{chunks}</span>,
              },
            )}
          </h2>
          {!isTablet && (
            <div
              className={classes.imgContainer}
              data-testid="ecp-map-image-mobile"
              aria-hidden="true"
            >
              <img src={mapMobile} alt="" />
            </div>
          )}
          <span
            data-testid="ecp-locator-landing-button"
            className={classes.button}
          >
            <FormattedMessage id="ecp.preview.button" />
          </span>
        </div>
        {isTablet && (
          <div
            className={classes.imgContainer}
            data-testid="ecp-map-image"
            aria-hidden="true"
          >
            <img src={map} alt="" />
          </div>
        )}
      </a>
    </section>
  );
};
